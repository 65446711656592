
import { Helmet } from "react-helmet";
import MainNavbar from "../nav/MainNavbar";
import ChangePassword from "./ChangePassword";
import DataPrivacyTab from "./DataPrivacyTab";
import TermsOfUse from "./TermsOfUse";

const Settings = () => {
    return (
        <div>
            <Helmet>
                <title>Connected Newsroom | Settings</title>
            </Helmet>
            <MainNavbar />
            <div className="container">
                <p className="my-queries-sub">Account Settings</p>
                <div className="account-container1">
                    <ul className="nav nav-tabsub" id="myTab" role="tablist">
                        <li className="nav-iteming">
                            <a className="nav-linking active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Change Password</a>
                        </li>
                        <li className="nav-iteming">
                            <a className="nav-linking" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Data & Privacy</a>
                        </li>
                        <li className="nav-iteming">
                            <a className="nav-linking" id="profile-tab1" data-toggle="tab" href="#profile1" role="tab" aria-controls="profile1" aria-selected="false">Terms of Use</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"><ChangePassword /></div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"><DataPrivacyTab /></div>
                        <div className="tab-pane fade" id="profile1" role="tabpanel" aria-labelledby="profile-tab1"><TermsOfUse /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Settings;